<template>
  <div id="Logo">
    <svg id="layer1" :data-src="$icons.logo" @click="goToPage('dashboard')"></svg>
    <!-- <svg id="layer2" :data-src="$icons.logo"></svg>
    <svg id="layer3" :data-src="$icons.logo"></svg> -->
  </div>
</template>

<script>
export default {
  name: "Logo"
};
</script>

<style scoped>
#Logo {
  height: 50px;
  width: 50px;
  position: relative;
  cursor: pointer;
  transition: ease-in-out .2s;

  &:hover>#layer1 {
    filter: invert(1);
    width: 120%;
    height: 120%;
  }

  &:hover>#layer2 {
    top: -10%;
    left: -10%;
    opacity: 1;
    transform: scale(1);
    filter: invert(1);
  }

  &:hover>#layer3 {
    top: 10%;
    left: 10%;
    opacity: 1;
    transform: scale(1);
  }
}

svg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: ease-in-out .2s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#layer1 {
  fill: var(--accent-1);
  z-index: 5;
  filter: invert(0);
}

#layer2 {
  fill: #00ca92;
  z-index: 4;
  opacity: 0;
  transform: scale(0.5);
}

#layer3 {
  fill: #004494;
  z-index: 3;
  opacity: 0;
  transform: scale(0.5);
}
</style>