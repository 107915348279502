import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import router from "@/router";
import i18n from "@/i18n";
import store from "./store";
import { defineRule } from "vee-validate";
import icons from "./components/Javascript/ui/icons";
import "external-svg-loader";
import tableConfigs from "./components/Javascript/tableConfigs";

const app = createApp(App);
app.config.globalProperties.$icons = icons;
app.config.globalProperties.$tableConfigs = tableConfigs;

defineRule("requiredArray", (value) => {
  if (!value || !value[0]) {
    return "this is a required field";
  }

  return true;
});

defineRule("positiveInt", (value) => {
  if (value && /^[1-9]\d*$/.test(value) === false) {
    return "this must be a positive integer";
  }

  return true;
});
// TOOLTIPS
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
app.use(FloatingVue)


// PROGRESS BAR
import VueProgressBar from "@aacassandra/vue3-progressbar";
const options = {
  color: "var(--accent-1)",
  failedColor: "var(--error-border)",
  thickness: "4px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};
app.use(VueProgressBar, options)


// ROUTER
app.use(router);
app.use(store);

// WINDOW SIZE
import { VueWindowSizePlugin } from "vue-window-size/option-api";
app.use(VueWindowSizePlugin);

// INTERNATIONALIZATION
app.use(i18n);

// TOAST
import Toast from "vue-toastification";
import { useToast } from "vue-toastification";
import TitledToast from "../src/components/TitledToast.vue"
const toast = useToast();
import "vue-toastification/dist/index.css";
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 3,
  newestOnTop: true,
  position: "bottom-right",
  pauseOnFocusLoss: false,
});

app.mount("#app");

//FUNCTIONS

app.config.globalProperties.goToPage = function (page, newTab) {
  if (newTab) {
    let routeData = this.$router.resolve(`/${store.state.locale}/` + page);
    window.open(routeData.href, "_blank");
  } else {
    this.$router.push(`/${store.state.locale}/` + page);
  }
};

app.config.globalProperties.scrollToTop = function () {
  window.scrollTo(0, 0);
};

app.config.globalProperties.successPop = function (message, route = '', translate = true) {
  toast.success({
    component: TitledToast,
    props: {
      url: '/' + route,
      message: translate ? this.$t(message) : message,
    }
  }, {
    duration: 3000,
    icon: true,
  });
};

app.config.globalProperties.errorPop = function (message, route = '') {
  toast.error({
    component: TitledToast,
    props: {
      url: '/' + route,
      message: this.$t(message),
    }
  }, {
    duration: 3000,
    icon: true,
  });
};

app.config.globalProperties.warningPop = function (message, route = '') {
  toast.info({
    component: TitledToast,
    props: {
      url: '/' + route,
      message: this.$t(message),
    }
  }, {
    duration: 3000,
    icon: true,
  });
};

//VUE SELECT

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
app.component("v-select", vSelect);

//vee-validate RULES
