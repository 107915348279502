let icons = {
  "arrow-right-to-bracket": require("@/assets/icons/arrow-right-to-bracket.svg"),
  atom: require("@/assets/icons/atom.svg"),
  barcode: require("@/assets/icons/barcode.svg"),
  bars: require("@/assets/icons/bars.svg"),
  bell: require("@/assets/icons/bell.svg"),
  "caret-down": require("@/assets/icons/caret-down.svg"),
  "caret-right": require("@/assets/icons/caret-right.svg"),
  "caret-up": require("@/assets/icons/caret-up.svg"),
  cerfa: require("@/assets/icons/cerfa.svg"),
  "chart-line": require("@/assets/icons/chart-line.svg"),
  validate: require("@/assets/icons/check.svg"),
  "circle-info": require("@/assets/icons/circle-info.svg"),
  close: require("@/assets/icons/close.svg"),
  comments: require("@/assets/icons/comments.svg"),
  copy: require("@/assets/icons/copy.svg"),
  database: require("@/assets/icons/database.svg"),
  download: require("@/assets/icons/download.svg"),
  edit: require("@/assets/icons/edit.svg"),
  envelope: require("@/assets/icons/envelope.svg"),
  flask: require("@/assets/icons/flask.svg"),
  folder: require("@/assets/icons/folder.svg"),
  tasks: require("@/assets/icons/tasks.svg"),
  "hard-drive": require("@/assets/icons/hard-drive.svg"),
  "house-chimney-medical": require("@/assets/icons/house-chimney-medical.svg"),
  "layer-group": require("@/assets/icons/layer-group.svg"),
  lightbulb: require("@/assets/icons/lightbulb.svg"),
  lock: require("@/assets/icons/lock.svg"),
  logo: require("@/assets/icons/logo.svg"),
  microscope: require("@/assets/icons/microscope.svg"),
  more: require("@/assets/icons/more.svg"),
  add: require("@/assets/icons/plus.svg"),
  "plus-circle": require("@/assets/icons/plus-circle.svg"),
  prescription: require("@/assets/icons/prescription.svg"),
  preview: require("@/assets/icons/preview.svg"),
  qrcode: require("@/assets/icons/qrcode.svg"),
  refresh: require("@/assets/icons/refresh.svg"),
  report: require("@/assets/icons/report.svg"),
  sms: require("@/assets/icons/sms.svg"),
  sort: require("@/assets/icons/sort.svg"),
  stethoscope: require("@/assets/icons/stethoscope.svg"),
  summary: require("@/assets/icons/summary.svg"),
  table: require("@/assets/icons/table.svg"),
  th: require("@/assets/icons/th.svg"),
  tick: require("@/assets/icons/tick.svg"),
  tracking: require("@/assets/icons/tracking.svg"),
  delete: require("@/assets/icons/trash.svg"),
  user: require("@/assets/icons/user.svg"),
  users: require("@/assets/icons/users.svg"),
  vials: require("@/assets/icons/vials.svg"),
  invalidate: require("@/assets/icons/invalidate.svg"),
  "invalidate-samples": require("@/assets/icons/invalidate-samples.svg"),
  annulr: require("@/assets/icons/trash.svg"),
  print: require("@/assets/icons/print.svg"),
  calculator: require("@/assets/icons/calculator.svg"),
  upload: require("@/assets/icons/upload.svg"),
  filter: require("@/assets/icons/filter.svg"),
  warning: require("@/assets/icons/warning.svg"),
  "arrow-right": require("@/assets/icons/arrow-right-solid.svg"),
  "arrow-left": require("@/assets/icons/arrow-left-solid.svg"),
  scan: require("@/assets/icons/scan.svg"),
  analysis_request_form: require("@/assets/icons/download-external-request-form.svg"),
  print_external: require("@/assets/icons/print-external.svg"),
  default: require("@/assets/icons/default.svg"),
  bank: require("@/assets/icons/bank.svg"),
};


module.exports = icons;