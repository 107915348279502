const dashboard = {
  name: "dashboard.pageTitle",
  link: "dashboard",
  icon: "table",
  key: "alwaysOn",
};

const spacer = {
  name: "spacer",
  spacerSize: "height: 2vh;",
  key: "alwaysOn",
};

const records = {
  name: "records.pageTitle",
  link: "table/records",
  icon: "folder",
  key: "admin_records",
};

const doctors = {
  name: "records.pageTitle",
  link: "table/doctors",
  icon: "folder",
  key: "admin_records",
};

const external_records = {
  name: "records.pageTitle",
  link: "table/external_records",
  icon: "folder",
  key: "admin_records",
};

const scanner = {
  name: "scanner.pageTitle",
  link: "scan",
  icon: "qrcode",
  key: "alwaysOn",
};

const prescribers = {
  name: "prescribers.pageTitle",
  link: "table/prescribers",
  icon: "stethoscope",
  key: "admin_prescribers",
};

const techniques = {
  name: "techniques.pageTitle",
  link: "table/techniques",
  icon: "atom",
  key: "admin_samples",
};

const analyses = {
  name: "analyses.pageTitle",
  link: "table/analyses",
  icon: "chart-line",
  key: "admin_analyses",
};

const parameters = {
  name: "parameters.pageTitle",
  link: "table/parameters",
  icon: "microscope",
  key: "admin_parameters",
};

const families = {
  name: "families.pageTitle",
  link: "table/families",
  icon: "layer-group",
  key: "admin_families",
};


const collectors = {
  name: "collectors.pageTitle",
  link: "table/collectors",
  icon: "th",
  key: "admin_collectors",
};


const machine = {
  name: "machines.pageTitle",
  link: "table/machines",
  icon: "hard-drive",
  key: "admin_machines",
};

const matrices = {
  name: "matrices.pageTitle",
  link: "table/matrices",
  icon: "flask",
  key: "admin_matrices",
};

const nabms = {
  name: "nabms.pageTitle",
  link: "table/nabms",
  icon: "barcode",
  key: "admin_nabms",
};

const sample_types = {
  name: "sample_types.pageTitle",
  class: "row ",
  link: "table/sample_types",
  icon: "vials",
  key: "admin_sample_types",
};
const other = {
  name: "dropdown",
  dropdownTitle: "misc.settings",
  controller: "Other",
  options: [families, machine, matrices, nabms, sample_types, parameters, analyses, collectors],
  key: "alwaysOn",
};

const users = {
  name: "users.pageTitle",
  link: "table/users",
  icon: "users",
  key: "admin_access",
};

const clinics = {
  name: "clinics.pageTitle",
  link: "table/clinics",
  icon: "house-chimney-medical",
  key: "admin_clinics",
};

const account = {
  name: "account.pageTitle",
  link: "account",
  icon: "user",
  key: "alwaysOn",
};

const importNav = {
  name: "import.pageTitle",
  link: "import",
  icon: "download",
  key: "alwaysOn",
};

const dataManagement = {
  name: "dataManagement.pageTitle",
  link: "data-management",
  icon: "database",
  key: "alwaysOn",
};


const uploads = {
  name: "uploads.pageTitle",
  link: "uploads",
  icon: "upload",
  key: "alwaysOn",
};

const info = {
  name: "info.pageTitle",
  link: "Information",
  icon: "circle-info",
  key: "alwaysOn",
};

const security = {
  name: "security.pageTitle",
  link: "security",
  icon: "lock",
  key: "alwaysOn",
};

const themeToogle = {
  name: "misc.themeToggle",
  icon: "lightbulb",
  key: "alwaysOn",
};

const settings = {
  name: "dropdown",
  dropdownTitle: "misc.accounts",
  link: "settings",
  controller: "Settings",
  options: [
    account,
    users,
    clinics,
    security,
    themeToogle,
    info,
  ],
  key: "alwaysOn",
};


const logout = {
  name: "misc.logout",
  icon: "arrow-right-to-bracket",
  key: "alwaysOn",
};

const navBarExternal = [
  spacer,
  external_records,
  prescribers,
  collectors,

  spacer,
  // dataManagement,
  uploads,

  spacer,
  account,
  security,
  info,
  themeToogle,

  spacer,
  logout,
  spacer,
  spacer,
];

const navBarPrescribers = [
  spacer,
  doctors,

  spacer,
  account,
  security,
  info,
  themeToogle,

  spacer,
  logout,
  spacer,
  spacer,
];

const navBarMap = [
  dashboard,
  records,
  prescribers,
  techniques,
  other,

  spacer,
  scanner,
  importNav,
  dataManagement,
  uploads,

  spacer,
  settings,
  spacer,
  logout,
  spacer,
  spacer,
];


const navBarMapMobile = [
  spacer,
  dashboard,
  records,
  prescribers,
  techniques,
  spacer,
  ...other.options,

  spacer,
  scanner,
  importNav,
  dataManagement,
  uploads,

  spacer,
  ...settings.options,

  spacer,
  logout,
];


module.exports = {
  navBarMap,
  navBarExternal,
  navBarMapMobile,
  navBarPrescribers,
};
