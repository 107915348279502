const patients = {
  // POST PATH FOR API
  add: "/add-patient",

  // HEADING TITLE OF ADD FORM
  addHeading: "formFields.patientCreateNew",

  // URL OF ADD NEW ITEM FORM
  addUrl: "add-record",

  //HEADING TITLE OF DELETE FORM
  deleteHeading: "records.deleteHeading",

  //HEADING TITLE OF DISABLED MOBILE FORM
  disabledHeading: "records.disabledHeading",

  //HEADING TITLE OF EDIT FORM
  editHeading: "records.editHeading",

  //NAME OF ID REQUIRED TO SEND TO API - TO CREATE EDIT ITEM FORM
  editId: "patient_id",

  //HEADING TITLE OF EXPORT FORM
  exportHeading: "records.exportHeading",

  //GET ALL PATH FOR API
  getAll: "/get-patients",

  //GET SINGLE PATH FOR API
  getSingle: "/get-patient",

  //RELATED ICON - WORKS WITH FONT AWESOME ICONS
  icon: "fa-solid fa-folder",

  //USED TO GENERATE PAGE NAME
  pageTitle: "records.pageTitle",

  //WHEN GETTING RESULTS FROM API - WILL SAVE RESULTS IN A ARRAY NAMED THIS
  save: "patients",

  //SEARCH BAR PLACEHOLDER TEXT
  searchPlaceholder: "tables.recordSearchPlaceholder",

  //USED TO GIVE API THE DEFAULT SORT-BY OPTION
  sortBy: ["sortOptions.desc.creationDate", "patient_last_updated", "DESC"],

  //SORT BY OPTIONS FOR TABLES
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "patient_last_updated",
      value: "ASC",
      ifFilter: false
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "patient_last_updated",
      value: "DESC",
      ifFilter: false
    },
  ],

  //URL FOR TABLE PAGE
  tableUrl: "table/records",

  //PUT PATH FOR API
  update: "/update-patient",
};

module.exports = patients;
