const nabms = {
  add: "/add-nabm",
  addHeading: "nabms.addHeading",
  addUrl: "add-nabm",
  deleteHeading: "nabms.deleteHeading",
  deletePath: "/delete-nabms",

  disabledHeading: "nabms.disabledHeading",
  editHeading: "nabms.editHeading",
  editId: "nabm_code",
  export: "/export-nabms",
  exportHeading: "nabms.exportHeading",
  getAll: "/get-nabms",
  getSingle: "/get-nabm",
  icon: "barcode",
  pageTitle: "nabms.pageTitle",
  save: "nabms",
  searchPlaceholder: "tables.nabmsSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "nabm_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "nabm_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "nabm_last_updated",
      value: "DESC",
    },
  ],
  staticFilterOptions: [
    {
      transKey: "nabms.refundable",
      columnName: "nabm_refund",
      value: "TRUE",
    },
    {
      transKey: "nabms.nonRefundable",
      columnName: "nabm_refund",
      value: "FALSE",
    },
  ],
  tableUrl: "table/nabms",
  update: "/update-nabm",
};

module.exports = nabms;
