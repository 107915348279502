const families = {
  add: "/add-family",
  addHeading: "families.addHeading",
  addUrl: "add-family",
  deleteHeading: "families.deleteHeading",
  deletePath: "/delete-families",
  disabledHeading: "families.disabledHeading",
  editHeading: "families.editHeading",
  editId: "family_id",
  export: "/export-families",
  exportHeading: "families.exportHeading",
  getAll: "/get-families",
  getSingle: "/get-family",
  icon: "layer-group",
  pageTitle: "families.pageTitle",
  save: "families",
  searchPlaceholder: "tables.familiesSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "family_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "family_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "family_last_updated",
      value: "DESC",
    },
    {
      transKey: "sortOptions.asc.rank",
      columnName: "family_rank",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.rank",
      columnName: "family_rank",
      value: "DESC",
    },
  ],
  tableUrl: "table/families",
  update: "/update-family",
};

module.exports = families;
