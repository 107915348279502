const clinics = {
  add: "/add-clinic",
  addHeading: "clinics.addHeading",
  deleteHeading: "clinics.deleteHeading",
  deletePath: "/delete-clinics",

  disabledHeading: "clinics.disabledHeading",
  editHeading: "clinics.editHeading",
  editId: "clinic_id",
  export: "/export-clinics",
  exportHeading: "clinics.exportHeading",
  getAll: "/get-clinics",
  getSingle: "/get-clinic",
  icon: "house-chimney-medical",
  pageTitle: "clinics.pageTitle",
  save: "clinics",
  sortBy: ["", "clinic_name", "DESC", false],
  tableUrl: "table/clinics",
  update: "/update-clinic",
};

module.exports = clinics;
