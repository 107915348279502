import { createStore } from "vuex";
import i18n, { selectedLocale } from "@/i18n";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    locale: selectedLocale,
    loggedIn: false,
    nextUrl: null,
    userPermissions: {},
    theme: "light",
    access_token: null,
    refresh_token: null,
    auth_machine_id: null,
    uid: null,
  },
  mutations: {
    updateLocale(state, newLocale) {
      state.locale = newLocale;
    },
    updateNextUrl(state, nextUrl) {
      state.nextUrl = nextUrl;
    },
    updateLoggedIn(state, newState) {
      state.loggedIn = newState;
    },
    updateUserPermissions(state, newPermissions) {
      state.userPermissions = newPermissions;
    },
    updateTheme(state, newTheme) {
      state.theme = newTheme;
    },
    updateTokens(state, { access_token, refresh_token }) {
      state.access_token = access_token;
      state.refresh_token = refresh_token;
    },
    updateMachineId(state, newMachineId) {
      state.auth_machine_id = newMachineId
    },
    updateUid(state, newUid) {
      state.uid = newUid;
    }
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.global.locale.value = newLocale;
      commit("updateLocale", newLocale);
    },
    changeNextUrl({ commit }, newNextUrl) {
      commit("updateNextUrl", newNextUrl);
    },
    changeLogInStatus({ commit }, logInStatus) {
      commit("updateLoggedIn", logInStatus);
    },
    changeUserPermissions({ commit }, newPermissions) {
      commit("updateUserPermissions", newPermissions);
    },
    changeTheme({ commit }, newTheme) {
      commit("updateTheme", newTheme);
      document.documentElement.setAttribute('data-theme', newTheme);
    },
    changeTokens({ commit }, { access_token, refresh_token }) {
      commit("updateTokens", { access_token, refresh_token });
    },
    changeMachineId({ commit }, newMachineId) {
      commit("updateMachineId", newMachineId);
    },
    changeUid({ commit }, newUid) {
      commit("updateUid", newUid);
    }
  },
  plugins: [createPersistedState()],
});
