<template>
	<button v-tooltip="tooltip" :class="`py-2 px-3 ${customClass}`">
		{{ btnText }}
		<slot>

		</slot>
	</button>
</template>

<script>
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "CustomButton",
	props: {
		btnText: {
			type: String
		},
		tooltip: {
			type: String,
			default: null,
		},
		btnType: {
			type: String,
			default: 'primary'
		}
	},
	computed: {
		customClass() {
			if (this.btnType == 'secondary') {
				return `secondary`;
			}
			else if (this.btnType == 'tertiary') {
				return `tertiary`;
			}
			else if (this.btnType == 'nextgen') {
				return "nextgen";
			}

			return 'primary';
		}
	}
};
</script>


<style scoped>
button {
	border-radius: 10px;
	display: inline-block;
	cursor: pointer;
	transition: all ease-in-out .2s;
	text-transform: uppercase;
	font-weight: 500;
	box-shadow: var(--button-shadow);

	&:disabled {
		cursor: not-allowed;
		background-color: var(--disabled) !important;
		color: var(--input-border) !important;

		&:hover {
			cursor: not-allowed;
			background-color: var(--disabled) !important;
			color: var(--input-border) !important;
		}
	}

	&:active:enabled {
		box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0);
		transform: translateY(-0.5px);
	}
}

.primary {
	background-color: var(--accent-1);
	color: var(--icon);

	&:hover {
		background-color: #3052b2;
	}
}

.secondary {
	background-color: var(--accent-2);
	color: var(--icon);

	&:hover {
		background-color: #2eaf8d;
	}
}

.tertiary {
	background-color: #f0f0f0;
	color: var(--primary-text);

	&:hover {
		background-color: var(--disabled);
	}
}

.nextgen {
	background: linear-gradient(to top left, var(--accent-1), var(--accent-2));
	color: var(--icon);
	border-radius: 0px;
	padding: 15px 20px !important;
	min-width: 150px;


	&:hover {
		background: linear-gradient(to top right, var(--accent-2), var(--accent-1));
		transition: all;
	}

	&:disabled {
		background: var(--background);
		border: 2px solid var(--input-border);
		box-shadow: none;
	}
}
</style>
