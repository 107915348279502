const records = {
  // POST PATH FOR API
  add: "/add-patient",

  //HEADING TITLE OF ADD FORM
  addHeading: "records.addHeading",

  //URL OF ADD NEW ITEM FORM
  addUrl: "add-record",

  //HEADING TITLE OF DELETE FORM
  deleteHeading: "records.deleteHeading",

  //HEADING TITLE OF DISABLED MOBILE FORM
  disabledHeading: "records.disabledHeading",

  //HEADING TITLE OF EDIT FORM
  editHeading: "records.editHeading",

  //NAME OF ID REQUIRED TO SEND TO API - TO CREATE EDIT ITEM FORM
  editId: "record_id",

  export: "/export-records",

  //HEADING TITLE OF EXPORT FORM
  exportHeading: "records.exportHeading",

  //GET ALL PATH FOR API
  getAll: "/get-records",

  //GET SINGLE PATH FOR API
  getSingle: "/get-record",

  //RELATED ICON - WORKS WITH FONT AWESOME ICONS
  icon: "folder",

  //USED TO GENERATE PAGE NAME
  pageTitle: "records.pageTitle",

  //WHEN GETTING RESULTS FROM API - WILL SAVE RESULTS IN A ARRAY NAMED THIS
  save: "patients",

  //SEARCH BAR PLACEHOLDER TEXT
  searchPlaceholder: "tables.recordSearchPlaceholder",

  //USED TO GIVE API THE DEFAULT SORT-BY OPTION
  sortBy: ["sortOptions.desc.creationDate", "record_last_updated", "DESC"],

  //SORT BY OPTIONS FOR TABLES
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "record_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "record_last_updated",
      value: "DESC",
    },
  ],

  staticFilterOptions: [
    {
      transKey: "records.status0",
      columnName: "record_status",
      value: "0",
    },
    {
      transKey: "records.status1",
      columnName: "record_status",
      value: "1",
    },
    {
      transKey: "records.status2",
      columnName: "record_status",
      value: "2",
    },
    {
      transKey: "records.status3",
      columnName: "record_status",
      value: "3",
    },
    {
      transKey: "records.status4",
      columnName: "record_status",
      value: "4",
    },
    {
      transKey: "records.status5",
      columnName: "record_status",
      value: "5",
    },

    {
      transKey: "formFields.recordMustFax",
      columnName: "record_pending_fax",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustPhone",
      columnName: "record_pending_phone",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustPost",
      columnName: "record_pending_post",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustOnline",
      columnName: "record_pending_online",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustPaper",
      columnName: "record_pending_paper",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustInsuranceReport",
      columnName: "record_pending_insurance_report",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustOccupationalPhysician",
      columnName: "record_pending_occupational_physician",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordIsUrgent",
      columnName: "record_is_urgent",
      value: "TRUE",
    },
    {
      transKey: "formFields.pregnant",
      columnName: "record_pregnant",
      value: "TRUE",
    },
    {
      transKey: "formFields.unresolvedNCA",
      columnName: "record_unresolved_nca",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordDerogFlag",
      columnName: "record_derog_flag",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordUhFlag",
      columnName: "record_uh_flag",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordDelaiFlag",
      columnName: "record_delai_flag",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordOralFlag",
      columnName: "record_oral_flag",
      value: "TRUE",
    },
    {
      transKey: "formFields.lblocFlag",
      columnName: "record_lbloc_flag",
      value: "TRUE",
    },
    {
      transKey: "btns.annulr",
      columnName: "record_annulr_flag",
      value: "TRUE",
    },
    {
      transKey: "formFields.lbloc",
      columnName: "record_lbloc_flag",
      value: "TRUE",
    },
    {
      transKey: "dashboard.tasksInProgress.withANE",
      columnName: "records_with_anes",
      value: "NULL",
    },
    {
      transKey: "dashboard.tasksInProgress.withNCT",
      columnName: "records_with_ncts",
      value: "NULL",
    },
    {
      transKey: "dashboard.tasksInProgress.validatedToday",
      columnName: "records_validated_today",
      value: "NULL",
    },
    {
      transKey: "dashboard.tasksInProgress.sentExternally",
      columnName: "record_sent_externally",
      value: "TRUE",
    },
    {
      transKey: "records.hasMultiprescription",
      columnName: "record_multiprescription",
      value: "TRUE",
    },
  ],

  dbPopulatedFilters: [
    {
      placeholder: "filterOptions.byCollector",
      contentName: "collectors",
      contentSavedTo: "collectors",
      filterColumnName: "record_collector",
      optionText: (opt) => `${opt.collector_surname}, ${opt.collector_name} - ${opt.clinic_code}`,
      optionValue: (opt) => opt.collector_id,
      rights: { admin_collectors: 1 },
    },
    {
      placeholder: "filterOptions.byPrescriber",
      contentName: "prescribers",
      contentSavedTo: "prescribers",
      filterColumnName: "record_prescriber",
      optionText: (opt) => `${opt.prescriber_surname}, ${opt.prescriber_name} ${opt.prescriber_adeli ? `- ${opt.prescriber_adeli}` : ''} - ${opt.clinic_code}`,
      optionValue: (opt) => opt.prescriber_id,
      rights: { admin_prescribers: 1 },
    },
    {
      placeholder: "filterOptions.byClinic",
      contentName: "clinics",
      contentSavedTo: "clinics",
      filterColumnName: "record_owner",
      optionText: (opt) => opt.clinic_code,
      optionValue: (opt) => opt.clinic_id,
      rights: { admin_clinics: 1 },
    }
  ],

  dateFilter: true,

  //URL FOR TABLE PAGE
  tableUrl: "table/records",

  //PUT PATH FOR API
  update: "/update-patient",
};

module.exports = records;
