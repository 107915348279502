const doctors = {
  // // POST PATH FOR API
  // add: "/add-patient",

  // // HEADING TITLE OF ADD FORM
  // addHeading: "records.addHeading",

  // // URL OF ADD NEW ITEM FORM
  // addUrl: "add-record",

  // // HEADING TITLE OF DELETE FORM
  // deleteHeading: "records.deleteHeading",

  // // HEADING TITLE OF DISABLED MOBILE FORM
  // disabledHeading: "records.disabledHeading",

  // // HEADING TITLE OF EDIT FORM
  // editHeading: "records.editHeading",

  // // NAME OF ID REQUIRED TO SEND TO API - TO CREATE EDIT ITEM FORM
  // editId: "record_id",

  // export: "/export-records",

  // HEADING TITLE OF EXPORT FORM
  // exportHeading: "records.exportHeading",

  // GET ALL PATH FOR API
  getAll: "/get-records",

  // GET SINGLE PATH FOR API
  // getSingle: "/get-record",

  //RELATED ICON - WORKS WITH FONT AWESOME ICONS
  icon: "folder",

  //USED TO GENERATE PAGE NAME
  pageTitle: "records.pageTitle",

  // WHEN GETTING RESULTS FROM API - WILL SAVE RESULTS IN A ARRAY NAMED THIS
  // save: "patients",

  // SEARCH BAR PLACEHOLDER TEXT
  searchPlaceholder: "tables.recordSearchPlaceholder",

  // USED TO GIVE API THE DEFAULT SORT-BY OPTION
  sortBy: ["sortOptions.desc.creationDate", "record_last_updated", "DESC"],

  // SORT BY OPTIONS FOR TABLES
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "record_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "record_last_updated",
      value: "DESC",
    },
  ],

  staticFilterOptions: [
    {
      transKey: "records.status0",
      columnName: "record_status",
      value: "0",
    },
    {
      transKey: "records.status1",
      columnName: "record_status",
      value: "1",
    },
    {
      transKey: "records.status2",
      columnName: "record_status",
      value: "2",
    },
    {
      transKey: "records.status3",
      columnName: "record_status",
      value: "3",
    },
    {
      transKey: "records.status4",
      columnName: "record_status",
      value: "4",
    },
    {
      transKey: "records.status5",
      columnName: "record_status",
      value: "5",
    },
  ],

  dbPopulatedFilters: [],
  dateFilter: true,

  // URL FOR TABLE PAGE
  tableUrl: "table/doctors",
};


module.exports = doctors;
