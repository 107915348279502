<template>
  <div id='LoginPage' class="container-fluid h-100 p-0 m-0">
    <div class="row h-100 align-items-center p-0 m-0">
      <!-- FORMS SIDE -->
      <div class="col-12 col-lg-7 col-xl-6 p-0 m-0">

        <div class="px-5 px-md-5 mx-md-5" style="max-width: 600px;">

          <!-- LOGO -->
          <div class="row">
            <div class="col-12">
              <Logo style="width: 80px; height: 80px;" />
            </div>
          </div>

          <!-- LOGIN FORM -->
          <form class="row justify-content-center align-items-center" v-if="!forgotPassword && !otpForm"
            @submit.prevent="login">
            <!-- Title -->
            <div class="col-12 login-title px-4">
              <h4>
                {{ $t("login.pageTitle") }}
              </h4>
            </div>

            <!-- Fields -->
            <div class="col-12 input-with-icon mb-3 px-4 pb-2">
              <input v-model="email" type="email" name="email" :placeholder="$t('formFields.email')" required />
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </div>

            <div class="col-12 input-with-icon mb-3 px-4 pb-2">
              <input v-model="password" type="password" name="password" :placeholder="$t('formFields.password')"
                required />
              <i class="fa fa-lock" aria-hidden="true"></i>
            </div>

            <div class="col-12 forgot-password-text mb-3 px-4">
              <span style="cursor: pointer; width: auto" @click="forgotPassword = true">
                <p>{{ $t("login.forgotPasswordQuestion") }}</p>
              </span>
            </div>

            <!-- Submit -->
            <div class="col-12 px-4 mt-4">
              <CustomButton :btnText="$t('btns.connect')" type="submit" btn-type="nextgen" />
            </div>

          </form>

          <!-- FORGOT PASSWORD FORM -->
          <form class="row justify-content-center align-items-center" v-if="forgotPassword"
            @submit.prevent="requestPasswordReset">

            <!-- Title -->
            <div class="col-12 login-title px-4">
              <h4>
                {{ $t("login.forgotPasswordPrompt") }}
              </h4>
            </div>

            <!-- Fields -->
            <div class="col-12 input-with-icon mb-3 px-4 pb-2">
              <input v-model="recoveryEmail" type="email" name="email" :placeholder="$t('formFields.email')" required />
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </div>

            <div class="col-12 forgot-password-text mb-3 px-4">
              <span style="cursor: pointer" @click="forgotPassword = false">
                <p>
                  {{ $t("login.backToConnection") }}
                </p>
              </span>
            </div>

            <!-- Submit -->
            <div class="col-12 px-4 mt-4">
              <CustomButton :btnText="$t('btns.send')" class="mt-4" type="submit" btn-type="nextgen" />
            </div>

          </form>

          <!-- OTP FORM -->
          <form class="row justify-content-center align-items-center" v-if="otpForm" @submit.prevent="loginWithOtp">
            <!-- Title -->
            <div class="col-12 login-title px-4">
              <h4>
                {{ $t("login.otpPrompt") }}
              </h4>
            </div>

            <!-- Fields -->
            <div class="col-12 input-with-icon mb-3 px-4 pb-2">
              <input v-model="otp" type="number" name="otp" :placeholder="$t('formFields.otp')" required />
              <i class="fa fa-lock" aria-hidden="true"></i>
            </div>

            <div class="col-12 forgot-password-text mb-3 px-4">
              <span style="cursor: pointer" @click="otpForm = false">
                <p>
                  {{ $t("login.backToConnection") }}
                </p>
              </span>
            </div>

            <!-- Submit -->
            <div class="col-12 px-4 mt-4">
              <CustomButton :btnText="$t('btns.connect')" class="mt-4" type="submit" btn-type="nextgen" />
            </div>

          </form>

          <!-- MADE BY -->
          <div class="row pt-5">
            <div class="col-12 px-4">
              <div class="flex no-wrap">
                <span>
                  {{ $t("login.productOf") }}
                  <a class="mr-1" href="https://zedsoft.co.uk/" target="_blank">
                    <b>Zedsoft</b>
                  </a>
                </span>
                <img src="/assets/images/zedsoft-logo.png" alt="zedsoft logo" class="w-4"
                  style="object-fit: contain;" />
              </div>
            </div>
          </div>

        </div>

      </div>

      <!-- PICTURE SIDE -->
      <div class="d-none d-lg-flex col-5 col-xl-6 h-100 p-0 m-0">
        <img class="h-100 w-100" style="object-fit: cover;" src="../assets/img/login.jpeg" />
      </div>

    </div>
  </div>
</template>

<script>
import { sha256 } from "js-sha256";
import {
  REQUESTS,
  apiAuth,
  buildRequest,
  machineIdGen,
  apiMain,
  errorHandler,
} from "../components/Javascript/methods";
import axios from "@/axiosClient";
import Logo from "@/components/Logo.vue";
import CustomButton from "@/components/CustomButton.vue";

export default {
  name: "Login",
  data() {
    return {
      errorHandler,
      email: "",
      recoveryEmail: "",
      password: "",
      forgotPassword: false,
      otpForm: false,
      otp: "",
    };
  },
  beforeMount() {
    this.$Progress.start();
  },
  mounted() {
    document.title = this.$t("tabTitle.login");
    this.$Progress.finish();
  },
  methods: {
    async login() {
      const apiLogin = apiAuth + "/login";
      const body = {
        email: this.email,
        auth_machine_id: machineIdGen(),
        password: sha256(this.password).toString(),
        locale: this.$store.state.locale,
      };
      var config = buildRequest(REQUESTS.POST, apiLogin, body);
      await axios(config)
        .then((response) => {
          if (response.data.otpSent) {
            this.otpForm = true;
          }
          else {
            this.$store.dispatch("changeTokens", {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token,
            });
            this.$store.dispatch("changeUid", response.data.uid);
            this.$store.dispatch("changeMachineId", body.auth_machine_id);
            if (this.$store.state.nextUrl) {
              this.$router.push({ path: this.$store.state.nextUrl });
              this.$store.dispatch("changeNextUrl", null);
            }
            else {
              this.goToPage("dashboard");
            }
          }
        })
        .catch((error) => {
          this.errorHandler(error);
        });
    },
    async requestPasswordReset() {
      const apiRoute = apiMain + "/request-password-reset";
      const body = {
        email: this.recoveryEmail,
        locale: this.$store.state.locale,
      };
      var config = buildRequest(REQUESTS.PUT, apiRoute, body);
      axios(config)
        .then((response) => {
          if (response.data.sent) {
            this.successPop("toasts.success", response.config.url.split("/").at(-1));
            this.forgotPassword = false;
          }
        })
        .catch((error) => {
          this.errorHandler(error);
        });
    },
    async loginWithOtp() {
      const apiLogin = apiAuth + "/login-with-otp";
      const body = {
        email: this.email,
        auth_machine_id: machineIdGen(),
        otp_code: String(this.otp).padStart(6, "0"),
      };
      var config = buildRequest(REQUESTS.POST, apiLogin, body);
      await axios(config)
        .then((response) => {
          this.$store.dispatch("changeTokens", {
            access_token: response.data.access_token,
            refresh_token: response.data.refresh_token,
          });
          this.$store.dispatch("changeUid", response.data.uid);
          this.$store.dispatch("changeMachineId", body.auth_machine_id);

          if (this.$store.state.nextUrl) {
            this.$router.push({ path: this.$store.state.nextUrl });
            this.$store.dispatch("changeNextUrl", null);
          }
          else {
            this.goToPage("dashboard");
          }
        })
        .catch((error) => {
          this.errorHandler(error);
        });
    },
  },
  components: { Logo, CustomButton }
};
</script>

<style scoped>
.login-title {
  height: 50px;
  margin-bottom: 30px;
}

input {
  padding-left: 15% !important;
}

.input-with-icon {
  position: relative;
}

.input-with-icon i {
  position: absolute;
  left: 12%;
  top: 32%;
  opacity: 0.3;
  color: #000;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
