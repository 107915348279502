const matrices = {
  add: "/add-matrix",
  addHeading: "matrices.addHeading",
  addUrl: "add-matrix",
  deleteHeading: "matrices.deleteHeading",
  deletePath: "/delete-matrices",

  disabledHeading: "matrices.disabledHeading",
  editHeading: "matrices.editHeading",
  editId: "matrix_id",
  export: "/export-matrices",
  exportHeading: "matrices.exportHeading",
  getAll: "/get-matrices",
  getSingle: "/get-matrix",
  icon: "flask",
  pageTitle: "matrices.pageTitle",
  save: "matrices",
  searchPlaceholder: "tables.matricesSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "matrix_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "matrix_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "matrix_last_updated",
      value: "DESC",
    },
  ],
  tableUrl: "table/matrices",
  update: "/update-matrix",
};

module.exports = matrices;
