const collector = {
  add: "/add-collector",
  addHeading: "collectors.addHeading",
  addUrl: "add-collector",
  deleteHeading: "collectors.deleteHeading",
  deletePath: "/delete-collectors",
  disabledHeading: "collectors.disabledHeading",
  editHeading: "collectors.editHeading",
  editId: "collector_id",
  export: "/export-collectors",
  exportHeading: "collectors.exportHeading",
  getAll: "/get-collectors",
  getSingle: "/get-collector",
  icon: "th",
  pageTitle: "collectors.pageTitle",
  save: "collectors",
  searchPlaceholder: "tables.collectorSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "collector_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "collector_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "collector_last_updated",
      value: "DESC",
    },
  ],
  dbPopulatedFilters: [
    {
      placeholder: "filterOptions.byClinic",
      contentName: "clinics",
      contentSavedTo: "clinics",
      filterColumnName: "collector_owner",
      optionText: (opt) => opt.clinic_code,
      optionValue: (opt) => opt.clinic_id,
      rights: { admin_clinics: 1 },
    },
  ],
  tableUrl: "table/collector",
  update: "/update-collector",
};

module.exports = collector;
