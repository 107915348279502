import axios from "axios";
import interceptorInjector from '@/interceptor/interceptor'

// CLIENT
const client = axios.create({
  headers: {
    'Authorization': "Bearer " + "initToken"
  }
});

interceptorInjector(client);

export default client;
