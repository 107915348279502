const parameters = {
  add: "/add-parameter",
  addHeading: "parameters.addHeading",
  addUrl: "add-parameter",
  deleteHeading: "parameters.deleteHeading",
  deletePath: "/delete-parameters",

  disabledHeading: "parameters.disabledHeading",
  editHeading: "parameters.editHeading",
  editId: "parameter_id",
  export: "/export-parameters",
  exportHeading: "parameters.exportHeading",
  getAll: "/get-parameters",
  getSingle: "/get-parameter",
  icon: "microscope",
  pageTitle: "parameters.pageTitle",
  save: "parameters",
  searchPlaceholder: "tables.parametersSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "parameter_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "parameter_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "parameter_last_updated",
      value: "DESC",
    },
  ],
  staticFilterOptions: [
    {
      transKey: "filterOptions.hiddenInReport",
      columnName: "parameter_hidden",
      value: "TRUE",
    },
    {
      transKey: "filterOptions.visibleInReport",
      columnName: "parameter_hidden",
      value: "FALSE",
    },
  ],
  dbPopulatedFilters: [
    {
      placeholder: "filterOptions.byMachine",
      contentName: "machines",
      contentSavedTo: "machines",
      filterColumnName: "parameter_machine",
      optionText: (opt) => opt.machine_name,
      optionValue: (opt) => opt.machine_id,
      rights: { admin_machines: 1 },
    },
    {
      placeholder: "filterOptions.byFamily",
      contentName: "families",
      contentSavedTo: "families",
      filterColumnName: "parameter_family",
      optionText: (opt) => opt.family_name,
      optionValue: (opt) => opt.family_id,
      rights: { admin_families: 1 },
    },
  ],
  tableUrl: "table/parameters",
  update: "/update-parameter",
};

module.exports = parameters;
