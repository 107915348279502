const patients = require("./TableConfigs/patients");
const families = require("./TableConfigs/families");
const collectors = require("./TableConfigs/collectors");
const machines = require("./TableConfigs/machines");
const analyses = require("./TableConfigs/analyses");
const matrices = require("./TableConfigs/matrices");
const nabms = require("./TableConfigs/nabms");
const parameters = require("./TableConfigs/parameters");
const prescribers = require("./TableConfigs/prescribers");
const sample_types = require("./TableConfigs/sample_types");
const techniques = require("./TableConfigs/techniques");
const users = require("./TableConfigs/users");
const clinics = require("./TableConfigs/clinics");
const records = require("./TableConfigs/records");
const tasks = require("./TableConfigs/tasks");
const doctors = require("./TableConfigs/doctors");
const external_records = require("./TableConfigs/external_records");

const tableConfigs = {
  doctors,
  external_records,
  analyses,
  clinics,
  families,
  machines,
  matrices,
  nabms,
  parameters,
  patients,
  prescribers,
  sample_types,
  techniques,
  users,
  records,
  collectors,
  duplicates: {},
  dashboard: {
    pageTitle: "dashboard.pageTitle",
    icon: "table",
  },
  tasks,
  uploads: {
    pageTitle: "uploads.pageTitle",
    icon: "upload"
  }
};

module.exports = tableConfigs;
