const analyses = {
  add: "/add-analysis",
  addHeading: "analyses.addHeading",
  addUrl: "add-analysis",
  deleteHeading: "analyses.deleteHeading",
  deletePath: "/delete-analyses",
  disabledHeading: "analyses.disabledHeading",
  editHeading: "analyses.editHeading",
  editId: "analysis_id",
  export: "/export-analyses",
  exportHeading: "analyses.exportHeading",
  getAll: "/get-analyses",
  getSingle: "/get-analysis",
  icon: "chart-line",
  pageTitle: "analyses.pageTitle",
  save: "analyses",
  searchPlaceholder: "tables.analysesSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "analysis_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "analysis_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "analysis_last_updated",
      value: "DESC",
    },
  ],
  staticFilterOptions: [
    {
      transKey: "filterOptions.visibleAnalyses",
      columnName: "analysis_visible",
      value: "TRUE",
    },
    {
      transKey: "filterOptions.hiddenAnalyses",
      columnName: "analysis_visible",
      value: "FALSE",
    },
    {
      transKey: "formFields.requiresSamplingTime",
      columnName: "analysis_requires_sampling_time",
      value: "TRUE",
    },
    {
      transKey: "formFields.isAccredited",
      columnName: "analysis_accredited",
      value: "TRUE",
    },
  ],
  dbPopulatedFilters: [
    {
      placeholder: "filterOptions.byMatrix",
      contentName: "matrices",
      contentSavedTo: "matrices",
      filterColumnName: "analysis_matrix",
      optionText: (opt) => opt.matrix_name,
      optionValue: (opt) => opt.matrix_id,
      rights: { admin_matrices: 1 },
    },
  ],
  tableUrl: "table/analyses",
  update: "/update-analysis",
};

module.exports = analyses;
