const users = {
  add: "/add-new-user",
  addExternal: "/add-new-external-user",
  addHeading: "users.addHeading",
  disabledHeading: "users.disabledHeading",
  editHeading: "users.editHeading",
  editId: "admin_uid",
  export: "/export-users",
  getAll: "/get-admins",
  getSingle: "/get-admin",
  icon: "users",
  pageTitle: "users.pageTitle",
  save: "users",
  sortBy: ["sortOptions.desc.creationDate", "user_name", "ASC", false],
  sortByOptions: [],
  tableUrl: "table/users",
  update: "/update-admin-access",
};

module.exports = users;
