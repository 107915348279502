const prescribers = {
  add: "/add-prescriber",
  addHeading: "prescribers.addHeading",
  addUrl: "add-prescriber",
  deleteHeading: "prescribers.deleteHeading",
  deletePath: "/delete-prescribers",
  disabledHeading: "prescribers.disabledHeading",
  editHeading: "prescribers.editHeading",
  editId: "prescriber_id",
  export: "/export-prescribers",
  exportHeading: "prescribers.exportHeading",
  getAll: "/get-prescribers",
  getSingle: "/get-prescriber",
  icon: "stethoscope",
  pageTitle: "prescribers.pageTitle",
  save: "prescribers",
  searchPlaceholder: "tables.prescriberSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "prescriber_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "prescriber_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "prescriber_last_updated",
      value: "DESC",
    },
  ],
  tableUrl: "table/prescribers",
  update: "/update-prescriber",
};

module.exports = prescribers;
