const machines = {
  add: "/add-machine",
  addHeading: "machines.addHeading",
  addUrl: "add-machine",
  deleteHeading: "machines.deleteHeading",
  deletePath: "/delete-machines",

  disabledHeading: "machines.disabledHeading",
  editHeading: "machines.editHeading",
  editId: "machine_id",
  export: "/export-machines",
  exportHeading: "machines.exportHeading",
  getAll: "/get-machines",
  getSingle: "/get-machine",
  icon: "hard-drive",
  pageTitle: "machines.pageTitle",
  save: "machines",
  searchPlaceholder: "tables.machineSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "machine_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "machine_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "machine_last_updated",
      value: "DESC",
    },
  ],
  tableUrl: "table/machines",
  update: "/update-machine",
};

module.exports = machines;
