<template>
  <div id="NavBar" class="d-none d-lg-block fixed-top" @mouseover="hover = true"
    @mouseleave="hover = false; dropdown.Other = false; dropdown.Settings = false;">
    <span class="hover-menu z-index-100 position-absolute" :style="hoverMenuCss()">

      <!-- LOGO -->
      <Logo class="mt-3 mr-auto ml-2" />

      <!--   LOOP   -->
      <div class="ml-4 mt-4" v-for="(navBarLink, i) in navBarMapDisplayed">

        <!--   IF SPACER  -->
        <div v-if="navBarLink.name === 'spacer'" :style="navBarLink.spacerSize"></div>

        <!--  IF DROPDOWN  -->
        <div v-if="navBarLink.name === 'dropdown'"
          @click="dropdown[navBarLink.controller] = !dropdown[navBarLink.controller]">
          <div class="d-flex align-items-center item" :style="`animation-delay: ${animationDelay * (i + 1)}ms;`">
            <svg class="mr-5" :data-src="$icons['caret-right']"
              :style="dropdown[navBarLink.controller] ? 'transform: rotate(90deg)' : 'transform: rotate(0deg)'"></svg>
            <p v-show="hover"> <!-- dropdown text title -->
              {{ $t(navBarLink.dropdownTitle) }}
            </p>
          </div>

          <!-- CONTENTS OF DROPDOWN  -->
          <Transition>
            <div v-if="dropdown[navBarLink.controller]">
              <div class="ml-4 mt-4" v-for="(optionsLink, j) in navBarLink.options">
                <div class="d-flex align-items-center item" :style="`animation-delay: ${animationDelay * (j + 1)}ms;`"
                  @click.left="handleLeftClick(optionsLink)" @click.middle="handleMiddleClick(optionsLink)">
                  <svg class="mr-5" :data-src="$icons[optionsLink.icon]"></svg>
                  <!-- icons in dropdown -->
                  <p v-show="hover"> <!-- dropdown text -->
                    {{ $t(optionsLink.name) }}
                  </p>
                </div>
              </div>
            </div>
          </Transition>
        </div>

        <!--  WIDE NAV BAR IF NEITHER SPACER NOR DROPDOWN  -->
        <div v-if="navBarLink.name !== 'spacer' && navBarLink.name !== 'dropdown'"
          class="d-flex align-items-center item" :style="`animation-delay: ${animationDelay * (i + 1)}ms;`"
          @click.left="handleLeftClick(navBarLink)" @click.middle="handleMiddleClick(navBarLink)">
          <svg class="mr-5" :data-src="$icons[navBarLink.icon]"></svg>
          <p v-show="hover"> <!-- icons text -->
            {{ $t(navBarLink.name) }}
          </p>
        </div>
      </div>
    </span>
  </div>

  <!-- HAMBURGER NAV BAR -->
  <div class="d-block d-lg-none w-100 m-0 p-0" style="height: 70px; z-index: 999; box-shadow: var(--button-shadow);">
    <div class="d-flex align-items-center justify-content-between h-100">
      <div class="d-flex justify-content-start align-items-center ml-5">
        <button v-if="this.$route.path.slice(4) === 'dashboard'" @click="this.goToPage('dashboard')">
          <img src="/assets/images/logo.png" style="width: 50px" />
        </button>
        <div v-else>
          <p>
            <a @click="$router.go(-1)" class="backButton">
              <svg style="margin-left: 0px;" :data-src="$icons['arrow-left']"></svg>
            </a>
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center mr-5">
        <button type="button" @click="menuOpen = !menuOpen">
          <svg :data-src="$icons.bars"></svg>
        </button>
      </div>
    </div>
  </div>

  <!-- NAV MODAL -->
  <Transition>
    <div v-if="menuOpen" class="navModalOn" @click="menuOpen = !menuOpen">
      <!-- LOOP -->
      <div class="row">
        <div :class="$windowWidth >= 576 && navBarLink.name === 'spacer' ? 'col-12' : 'col-12 col-sm-6 mb-4'"
          v-for="navBarLink in navBarMapDisplayed">

          <!--   IF SPACER  -->
          <div v-if="navBarLink.name === 'spacer'" @click.stop
            :style="$windowWidth >= 576 && navBarLink.name === 'spacer' ? 'height: 40px' : ''">
          </div>

          <div v-if="navBarLink.name !== 'spacer'" class="d-flex align-items-center justify-content-center" @click.stop
            @click=handleLeftClick(navBarLink); style="cursor: pointer; overflow: hidden; width: fit-content;">
            <p class="mr-5">
              <svg :data-src="$icons[navBarLink.icon]" style="color: var(--accent-1); width: 25px;"></svg>
            </p>
            <p style="color: var(--accent-1);">
              {{ $t(navBarLink.name) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import axios from "@/axiosClient";
import {
  apiAuth,
  buildRequest,
  REQUESTS,
  errorHandler,
} from "./Javascript/methods";
import {
  navBarMap,
  navBarMapMobile,
  navBarExternal,
  navBarPrescribers,
} from "@/components/Javascript/NavBarMap";
import Logo from "./Logo.vue";

export default {
  name: "NavBar",
  components: { Logo },
  data() {
    return {
      errorHandler,
      hover: false,
      menuOpen: false,

      navBarMapDisplayed: [],

      navBarMap,
      navBarMapMobile,
      page: "dashboard",
      dropdown: { Other: false, Settings: false },

      animationDelay: 50
    };
  },

  mounted() {
    this.navBarManager();
  },

  watch: {
    $route: function () {
      this.navBarManager();
    },

    $windowWidth: function () {
      if (this.$windowWidth >= 992) {
        this.menuOpen = false;
      }

      this.navBarManager();
    },
  },

  methods: {

    handleLeftClick(navBarLink) {
      if (navBarLink.name === 'misc.logout') {
        this.logout();
      } else if (navBarLink.name === 'misc.themeToggle') {
        this.themeToggle();
      } else {
        this.goToPage(navBarLink.link);
      }
      this.menuOpen = false;
    },
    handleMiddleClick(navBarLink) {
      if (navBarLink.name === 'misc.logout') {
        this.logout();
      } else if (navBarLink.name === 'misc.themeToggle') {
        this.themeToggle();
      } else {
        this.goToPage(navBarLink.link, 'newTab');
      }
    },
    navBarManager() {

      // CHOOSE NAVBAR FOR EXTERNAL USERS OR NORMAL ONE
      if (this.$store.state.userPermissions.user_external == true) {
        this.navBarMapDisplayed = navBarExternal;
      }
      else if (this.$store.state.userPermissions.user_is_prescriber == true) {
        this.navBarMapDisplayed = navBarPrescribers;
      }
      else {

        // CHOOSE LAYOUT DEPENDANT ON SCREEN WIDTH
        if (this.$windowWidth < 992) {
          this.navBarMapDisplayed = this.navBarMapMobile;
        } else {
          this.navBarMapDisplayed = this.navBarMap;
        }

        // FILTER OPTIONS ACCORDING TO USER PERMISSIONS
        this.navBarMapDisplayed = this.navBarMapDisplayed.filter((navItem) => {

          //FOR "OTHER" DROPDOWN
          if (navItem.controller === "Other") {
            navItem.options = navItem.options.filter((dropdownOption) => {
              return Number(this.$store.state.userPermissions[dropdownOption.key]) > 0;
            });
            return navItem;
          }

          //FOR "SETTINGS" DROPDOWN
          else if (navItem.controller === "Settings") {
            let optionArr = navItem.options;

            let settingsArr = [];

            for (let i = 0; i < optionArr.length; i++) {
              if (optionArr[i].name === "users.pageTitle") {
                if (this.$store.state.userPermissions.admin_access == true) {
                  settingsArr.push(optionArr[i]);
                }
              } else {
                settingsArr.push(optionArr[i]);
              }
            }
            navItem.options = settingsArr;

            return navItem;
          }

          //FOR EVERYTHING ELSE
          else {
            return (
              (navItem.key === "alwaysOn" &&
                navItem.controller !== "Other" &&
                navItem.controller !== "Settings") ||
              Number(this.$store.state.userPermissions[navItem.key]) > 0
            );
          }
        });
      }
    },

    hoverMenuCss() {
      if (this.hover) {
        return "width: 300px; overflow-y: auto;";
      } else {
        return "width: 65px;";
      }
    },

    navigatePageMobile(link) {
      this.goToPage(link);
      this.page = link;
      this.menuOpen = false;
    },

    async logout() {
      const logoutData = {
        refresh_token: this.$store.state.refresh_token,
      };

      const apiLogout = apiAuth + "/logout";

      const config = buildRequest(REQUESTS.PUT, apiLogout, logoutData, true);

      await axios(config)
        .then(() => {
          this.$store.dispatch("changeTokens", { access_token: null, refresh_token: null });
          this.$store.dispatch("changeUid", null);
          this.$store.dispatch("changeMachineId", null);
          this.$store.dispatch("changeUserPermissions", {});

          this.goToPage("");
        })
        .catch((error) => {
          this.errorHandler(error);
        });
    },

    themeToggle() {
      if (document.documentElement.getAttribute("data-theme") === "light") {
        this.$store.dispatch("changeTheme", 'dark');
      } else {
        this.$store.dispatch("changeTheme", 'light');
      }
    }
  },
};
</script>

<style scoped>
p {
  color: var(--accent-1);
  user-select: none;
  transition: ease-in-out .2s;
  font-weight: bold;
  text-wrap: nowrap;
  white-space: nowrap;
}

svg {
  fill: var(--accent-1);
  width: 25px;
  height: 25px;
  transition: all ease-in .2s;
  padding: 3px;
  margin-left: 5px;
  overflow: visible;
}

.fixed-top {
  position: relative;
  width: 65px;
  height: 0px;
  background: var(--card-bg);
  box-shadow: var(--button-shadow);
  opacity: 1;
}

.hover-menu {
  height: 100vh;
  background: var(--card-bg);
  box-shadow: var(--button-shadow);
  opacity: 1;
  transition: ease-in-out 0.2s;
  overflow: hidden;
}

.backButton {
  color: var(--accent-1);
  cursor: pointer;
}

.item {
  transition: ease-in-out .2s;
  cursor: pointer;
  overflow: hidden;
  animation: fadeIn 1s forwards;
  opacity: 0;


  &:hover>svg,
  &:hover>p {
    filter: saturate(200%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navModalOn {
  padding: 10px 10px;
  display: block;
  position: fixed;
  z-index: 9000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--card-bg);
  opacity: 0.95;
}
</style>
