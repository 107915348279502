const sample_types = {
  add: "/add-sampletype",
  addHeading: "sample_types.addHeading",
  deleteHeading: "sample_types.deleteHeading",
  deletePath: "/delete-sampletypes",

  disabledHeading: "sample_types.disabledHeading",
  editHeading: "sample_types.editHeading",
  editId: "sample_type_id",
  export: "/export-sample-types",
  exportHeading: "sample_types.exportHeading",
  getAll: "/get-sampletypes",
  getSingle: "/get-sampletype",
  icon: "vials",
  pageTitle: "sample_types.pageTitle",
  save: "sample_types",
  searchPlaceholder: "tables.sampleTypeSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "sample_type_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "sample_type_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "sample_type_last_updated",
      value: "DESC",
    },
  ],
  tableUrl: "table/sample_types",
  update: "/update-sampletype",
};

module.exports = sample_types;
