import axios from "axios";
import store from "@/store";
import qs from "qs";
import { bugout } from "@/components/Javascript/methods";

const shouldIntercept = (error) => {
  try {
    return error.response.data === "accessTokenExpired" || error.response.data === "invalidAccessToken";
  } catch (e) {
    return false;
  }
};

const setTokenData = (tokenData = {}, axiosClient) => {
  bugout.log("Saving new tokens to vuex: ", tokenData);
  store.dispatch("changeTokens", {
    access_token: tokenData.access_token,
    refresh_token: tokenData.refresh_token,
  });
  store.dispatch("changeUserPermissions", tokenData.rights);
};

const apiAuth = "https://auth-dot-ninja-lims-350520.ew.r.appspot.com";

const handleTokenRefresh = () => {

  const refreshToken = {
    uid: store.state.uid,
    auth_machine_id: store.state.auth_machine_id,
    refresh_token: store.state.refresh_token,
  };
  bugout.log("Attempting refresh with payload: ", refreshToken);

  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${apiAuth}/refresh-tokens`,
      data: qs.stringify(refreshToken),
    })
      .then(({ data }) => {
        bugout.log("Refresh succeeded with data: ", data);
        resolve(data);
      })
      .catch((err) => {
        bugout.log("Refreshed failed with error: ", err);
        reject(err);
      })
  });
};

const attachTokenToRequest = (request, token) => {
  request.headers['Authorization'] = 'Bearer ' + token;

  // Token is also part of request
  if (/\/is-access-token-valid/.test(request.url)) {
    request.data = qs.stringify({
      uid: store.state.uid,
      auth_machine_id: store.state.auth_machine_id,
      access_token: token,
    });
  }

  if (/\/logout/.test(request.url)) {
    request.data = qs.stringify({
      refresh_token: store.state.refresh_token,
    });
  }

};

export default (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (error) => {

    if (!options.shouldIntercept(error)) {
      bugout.log("Decided not to intercept for error: ", error);
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      bugout.log("Request is already queued or retried", error.config);
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      }).then(token => {
        originalRequest._queued = true;
        options.attachTokenToRequest(originalRequest, token);
        bugout.log("Encuing request to retry", originalRequest);
        return axiosClient.request(originalRequest);
      }).catch(err => {
        bugout.log("Failed request retry with error: ", error)
        return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise((resolve, reject) => {
      options.handleTokenRefresh.call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData.access_token);
          processQueue(null, tokenData.access_token);
          bugout.log("Retrying request", originalRequest);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          bugout.log("Some weird error", err);
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        })
    });

  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};