const techniques = {
  add: "/add-sample",
  addHeading: "techniques.addHeading",
  deleteHeading: "techniques.deleteHeading",
  disabledHeading: "techniques.disabledHeading",
  editHeading: "techniques.editHeading",
  editId: "sample_id",
  export: "/export-samples",
  exportHeading: "techniques.exportHeading",
  getAll: "/get-samples",
  getSingle: "/get-sample",
  icon: "atom",
  pageTitle: "techniques.pageTitle",
  save: "samples",
  searchPlaceholder: "tables.techniqueSearchPlaceholder",
  sortBy: ["sortOptions.desc.creationDate", "sample_last_updated", "DESC"],
  sortByOptions: [
    {
      transKey: "sortOptions.asc.creationDate",
      columnName: "sample_last_updated",
      value: "ASC",
    },
    {
      transKey: "sortOptions.desc.creationDate",
      columnName: "sample_last_updated",
      value: "DESC",
    },
  ],
  staticFilterOptions: [
    {
      transKey: "formFields.recordMustFax",
      columnName: "record_pending_fax",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustPhone",
      columnName: "record_pending_phone",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustPost",
      columnName: "record_pending_post",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustOnline",
      columnName: "record_pending_online",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustPaper",
      columnName: "record_pending_paper",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustInsuranceReport",
      columnName: "record_pending_insurance_report",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordMustOccupationalPhysician",
      columnName: "record_pending_occupational_physician",
      value: "TRUE",
    },
    {
      transKey: "formFields.recordIsUrgent",
      columnName: "record_is_urgent",
      value: "TRUE",
    },
    {
      transKey: "formFields.pregnant",
      columnName: "record_pregnant",
      value: "TRUE",
    },
    {
      transKey: "dashboard.tasksInProgress.withNCT",
      columnName: "sample_nct_active",
      value: "TRUE",
    },
  ],
  dbPopulatedFilters: [
    {
      placeholder: "filterOptions.byClinic",
      contentName: "clinics",
      contentSavedTo: "clinics",
      filterColumnName: "record_owner",
      optionText: (opt) => opt.clinic_code,
      optionValue: (opt) => opt.clinic_id,
      rights: { admin_clinics: 1 },
    },
    {
      placeholder: "filterOptions.byMachine",
      contentName: "machines",
      contentSavedTo: "machines",
      filterColumnName: "sample_machine",
      optionText: (opt) => opt.machine_name,
      optionValue: (opt) => opt.machine_id,
      rights: { admin_machines: 1 },
    }
  ],
  tableUrl: "table/techniques",
  update: "/update-sample",
};

module.exports = techniques;
